// WRITE ARTICLE
export const addInternalName = {
  order: 2,
  name: "Add an internal name",
  subcategory: "Notes & Files",
  markdown: `# Add an internal name

  {% callout type="caution" %}
  **Important:** Internal names are intentionally private. Learn how to {% inlineRouterLink articleId="add-a-name" %}add or edit a public name{% /inlineRouterLink %}.
  {% /callout %}`,
};
